import React, { useState } from "react";

import { SanityRecipe } from "@graphql-types";
import { useStore } from "@state/store";
import { Container, GridContainer } from "@util/standard";
import { Button } from ".";
import RecipeCard from "./recipeCard";
interface Props {
  allRecipes?: boolean;
  recipes?: SanityRecipe[];
  algoliaRecipes?: SanityRecipe[];
  isBlogs?: boolean;
  blogs?: any;
}

function PreviewGrid(props: Props) {
  const [numbersToShow, setNumbersToShow] = useState(11);

  const { recipes, algoliaRecipes } = props;

  const { searchParams } = useStore();

  return (
    <div>
      {searchParams.hasSearched ? (
        <>
          <Container width="100%">
            <GridContainer columnGap="1%" rowGap={60} tabletRowGap={16} width="100%">
              {algoliaRecipes &&
                algoliaRecipes.map((recipe, index) => {
                  if (recipe == null) {
                    return null;
                  }

                  if (index > numbersToShow) {
                    return null;
                  }

                  if (recipe.hideRecipe) {
                    return null;
                  }

                  return (
                    <Container key={recipe._key}>
                      <RecipeCard recipeId={recipe.objectID} data={recipe} />
                    </Container>
                  );
                })}
            </GridContainer>
          </Container>
          {algoliaRecipes && numbersToShow <= algoliaRecipes?.length - 1 && (
            <Container margin="60px auto 0 auto">
              <Button
                margin="auto"
                theme="block"
                text="Load More"
                onClick={() => setNumbersToShow(numbersToShow + 12)}
              />
            </Container>
          )}
        </>
      ) : (
        <Container width="100%" flexDirection="column">
          <GridContainer columnGap="1%" rowGap={60} tabletRowGap={16} width="100%">
            {recipes &&
              recipes.map((recipe, index) => {
                if (recipe == null) {
                  return null;
                }

                if (index > numbersToShow) {
                  return null;
                }

                if (recipe.hideRecipe) {
                  return null;
                }

                return (
                  <Container key={recipe._key}>
                    <RecipeCard recipeId={recipe._id} data={recipe} />
                  </Container>
                );
              })}
          </GridContainer>
          {recipes && numbersToShow <= recipes?.length - 1 && (
            <Container margin="60px auto 0 auto">
              <Button
                margin="auto"
                theme="block"
                text="Load More"
                onClick={() => setNumbersToShow(numbersToShow + 12)}
              />
            </Container>
          )}
        </Container>
      )}
    </div>
  );
}

export default PreviewGrid;
