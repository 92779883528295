import React, { useEffect, useState } from "react";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import Hero from "@components/global/hero";
import PreviewGrid from "@components/global/previewGrid";
import { Container } from "@util/standard";
import FilterBar from "@components/global/filterBar";
import { isSanityColumnContent, isSanityCtaBlock, isSanityLearnCategoryPreview } from "@util/types";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import ColumnContent from "@components/global/columnContent";
import CtaBlock from "@components/global/ctaBlock";
import { useStore } from "@state/store";
import { isBrowser, searchClient } from "@util/helper";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { useRecipesQuery } from "@src/query";

interface Props extends PageProps {
  data: Query;
}

const DefaultRecipes = () => {
  const recipesQuery = useRecipesQuery();
  return <PreviewGrid recipes={recipesQuery} />;
};

const Hits = ({ hits }: any) => {
  return <PreviewGrid algoliaRecipes={hits} />;
};

const CustomHits = connectHits(Hits);

function Recipes({ data }: Props) {
  const { searchParams, clearSearchParams } = useStore();

  if (data == null || data.sanityRecipesLanding == null) {
    return null;
  }

  const { hero, pageContent, seo } = data.sanityRecipesLanding;

  useEffect(() => {
    if (isBrowser()) window.scrollTo({ top: 0 });
    // clearSearchParams();
  }, []);

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        <InstantSearch indexName="Recipes" searchClient={searchClient}>
          <Configure hitsPerPage={1000} />
          {hero && <Hero data={hero} searchBar isLearnPage isRecipePage />}
          <Container flexDirection="column" width="90%" margin="80px auto" tabletMargin="40px auto">
            <FilterBar />
            {searchParams.hasSearched ? <CustomHits /> : <DefaultRecipes />}
          </Container>
        </InstantSearch>

        {pageContent &&
          pageContent.map(data => {
            if (isSanityLearnCategoryPreview(data)) {
              return <LearnCategoryPreview data={data} key={data._key} />;
            }
            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} />;
            }
            if (isSanityCtaBlock(data)) {
              return <CtaBlock data={data} key={data._key} />;
            }
            return null;
          })}
      </Layout>
    </>
  );
}

export default Recipes;

export const query = graphql`
  {
    sanityRecipesLanding {
      hero {
        ...sanityHero
      }
      seo {
        ...sanitySeo
      }
      pageContent {
        ... on SanityLearnCategoryPreview {
          ...sanityLearnCategoryPreview
        }
        ... on SanityCtaBlock {
          ...sanityCtaBlock
        }
        ... on SanityColumnContent {
          ...sanityColumnContent
        }
      }
    }
  }
`;
