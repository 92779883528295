import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Collapse } from "react-collapse";

import { Container, P, A, H4 } from "@util/standard";
import { colors, colorsRGB, icons, TABLET_BREAKPOINT } from "@util/constants";
import { Query } from "@graphql-types";
import { useStore } from "@state/store";
import { Button } from ".";
import { useCheckScreenWidth } from "@util/hooks";
import { isBrowser } from "@util/helper";

const HeaderContainer = styled(Container)`
  width: 270px;
  border: 1px solid black;
  border-bottom: none;
  justify-content: space-between;
  cursor: pointer;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

const FilterContainer = styled(Container)`
  padding: 50px 0;
  width: 100%;
  height: 86%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: -1px;
  margin-bottom: 40px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    padding: 0;
  }
`;

const DietWrapper = styled(Container) <{ isHighlighted: boolean; }>`
  border: 1px solid ${colors.darkerGreen};
  border-radius: 3px;
  margin: 0 0 15px 0;
  width: max-content;
  cursor: pointer;
  background: ${colors.transparent};

  &:hover {
    background: ${colorsRGB.darkerGreen(0.05)};
  }

  img {
    filter: brightness(1);
  }
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
  background: ${colors.darkerGreen};
  img {
    filter: brightness(10);
  }
  `}

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }

  transition: all 0.2s;
`;

const Icon = styled.img<{ margin?: string; width?: string; height?: string; }>`
  margin-right: 20px;
  ${({ margin }) => margin && `margin: ${margin};`};
  ${({ width }) => width && `width: ${width};`};
  ${({ height }) => height && `height: ${height};`};
`;

function FilterBar()
{
  const {
    allSanityRecipeCategories,
    allSanityDifficulty,
    allSanityBudget,
    allSanityFlavours,
    allSanitySpecialDiets,
  }: Query = useStaticQuery(graphql`
    {
      allSanityRecipeCategories(sort: { order: ASC, fields: name }) {
        nodes {
          _key
          id
          name
          description
          image {
            ...sanityFullWidthImage
          }
        }
      }
      allSanityDifficulty(sort: { order: ASC, fields: name }) {
        nodes {
          _key
          id
          name
        }
      }
      allSanityBudget(sort: { order: ASC, fields: name }) {
        nodes {
          _key
          id
          name
        }
      }
      allSanityFlavours(sort: { order: ASC, fields: name }) {
        nodes {
          _key
          id
          name
          description
          image {
            ...sanityFullWidthImage
          }
        }
      }
      allSanitySpecialDiets(sort: { order: ASC, fields: name }) {
        nodes {
          _key
          id
          name
          description
          image {
            ...sanityFullWidthImage
          }
        }
      }
    }
  `);

  const [filterOpen, setFilterOpen] = useState(false);
  const { searchFilters, setHasSearched } = useStore();
  const { isLaptopWidth, isMobileWidth } = useCheckScreenWidth();

  const handleClick = (category: string) =>
  {
    if (searchFilters.includes(category))
    {
      const newFilters = searchFilters.filter((filter: string) => filter !== category);
      useStore.setState({ searchFilters: newFilters });
      setHasSearched(true);
    } else
    {
      const newFilters = searchFilters.concat(category);
      useStore.setState({ searchFilters: newFilters });
      setHasSearched(true);
    }
  };

  return (
    <>
      <Container width="100%" flexDirection="column" position="relative" id="searchResults">
        <Container
          margin={filterOpen ? "0" : "0 0 40px 0"}
          borderBottom={filterOpen ? "none" : "1px solid black"}
          width="100%"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Container width="100%">
            <HeaderContainer onClick={() => setFilterOpen(!filterOpen)}>
              <P margin="12px 0 12px 20px">Filter</P>
              <Icon height="9px" width="18px" margin="auto 20px auto 0" src={icons.arrow} />
            </HeaderContainer>
          </Container>
          {filterOpen && (
            <Container width="50%" justifyContent="flex-end">
              {searchFilters && searchFilters.length > 0 && <P>{searchFilters.length} selected</P>}
              {searchFilters && searchFilters.length > 0 && (
                <A margin="auto 0 auto 25px" underlined bold onClick={() => useStore.setState({ searchFilters: [] })}>
                  Clear filter
                </A>
              )}
            </Container>
          )}
        </Container>
        <Collapse isOpened={filterOpen}>
          <FilterContainer
            position={isLaptopWidth ? undefined : "fixed"}
            top={filterOpen ? "14%" : "100%"}
            backgroundColor="white"
            left="0px"
            overflow={isLaptopWidth ? "" : "scroll"}
            zIndex={5}
            margin="0 auto"
            transitionAll={0.2}
          >
            {!isLaptopWidth && (
              <Container
                width="100%"
                justifyContent="space-between"
                borderBottom={`1px solid ${colorsRGB.black(0.2)}`}
                position="fixed"
                backgroundColor="white"
                zIndex={4}
              >
                <P margin="20px 4%">Filter</P>
                <Icon
                  margin="20px 4%"
                  height="16px"
                  width="16px"
                  src={icons.closeDark}
                  onClick={() => setFilterOpen(false)}
                />
              </Container>
            )}
            {allSanitySpecialDiets && (
              <Container
                flexDirection="column"
                width="25%"
                tabletWidth="80%"
                tabletMargin="auto"
                mobilePadding="40px 0"
                borderBottom={isLaptopWidth ? "" : `1px solid ${colorsRGB.black(0.2)}`}
              >
                <H4 margin={isMobileWidth ? "60px 0 20px 0" : "0 0 20px 0"} fontWeight="normal">
                  Special Diets
                </H4>
                {allSanitySpecialDiets.nodes.map(diet =>
                {
                  if (diet == null || diet.name == null)
                  {
                    return null;
                  }
                  const isHighlighted = searchFilters.includes(diet.name);
                  return (
                    <DietWrapper
                      isHighlighted={isHighlighted}
                      onClick={() => handleClick(diet.name as string)}
                      key={diet.id}
                    >
                      <Icon margin="10px" src={diet.image?.asset?.url as string} />
                      <P color={isHighlighted ? "white" : "darkerGreen"} margin="auto 20px auto 0">
                        {diet.name}
                      </P>
                    </DietWrapper>
                  );
                })}
              </Container>
            )}
            {allSanityRecipeCategories && (
              <Container
                flexDirection="column"
                width="25%"
                tabletWidth="80%"
                tabletMargin="0px auto"
                mobilePadding="40px 0"
                borderBottom={isLaptopWidth ? "" : `1px solid ${colorsRGB.black(0.2)}`}
              >
                <H4 margin="0 0 20px 0" fontWeight="normal">
                  Category
                </H4>
                {allSanityRecipeCategories.nodes.map(category =>
                {
                  if (category == null || category.name == null)
                  {
                    return null;
                  }
                  const isHighlighted = searchFilters.includes(category.name);
                  return (
                    <Container
                      margin="0 0 12px 0"
                      onClick={() => handleClick(category.name as string)}
                      cursor="pointer"
                      key={category.id}
                    >
                      <Icon margin="0 10px 0 0" src={isHighlighted ? icons.checkedDark : icons.uncheckedDark} />
                      <P color="black" margin="auto 20px auto 0">
                        {category.name}
                      </P>
                    </Container>
                  );
                })}
              </Container>
            )}
            {allSanityDifficulty && (
              <Container
                flexDirection="column"
                width="25%"
                tabletWidth="80%"
                tabletMargin="0px auto"
                mobilePadding="40px 0"
                borderBottom={isLaptopWidth ? "" : `1px solid ${colorsRGB.black(0.2)}`}
              >
                <H4 margin="0px 0 20px 0" fontWeight="normal">
                  Budget
                </H4>
                <Container flexDirection={isLaptopWidth ? "column" : "row"} tabletMargin="0 0 40px 0">
                  {allSanityBudget &&
                    allSanityBudget.nodes.map(budget =>
                    {
                      if (budget == null || budget.name == null)
                      {
                        return null;
                      }
                      const isHighlighted = searchFilters.includes(budget.name);
                      return (
                        <Container
                          onClick={() => handleClick(budget.name as string)}
                          margin="0 0 10px 0"
                          opacity={isHighlighted ? 1 : 0.5}
                          borderRadius={3}
                          cursor="pointer"
                          tabletMargin="auto 15px auto 0"
                          width="max-content"
                          backgroundRGBColor={colorsRGB.darkerGreen(0.2)}
                          key={budget.id}
                        >
                          <P color="darkerGreen" margin="7px 12px">
                            {budget.name}
                          </P>
                        </Container>
                      );
                    })}
                </Container>
                <H4 margin={isLaptopWidth ? "80px 0 20px 0" : "0 0 20px 0"} fontWeight="normal">
                  Difficulty
                </H4>
                <Container flexDirection={isLaptopWidth ? "column" : "row"}>
                  {allSanityDifficulty.nodes.map(difficulty =>
                  {
                    if (difficulty == null || difficulty.name == null)
                    {
                      return null;
                    }

                    const isHighlighted = searchFilters.includes(difficulty.name);
                    return (
                      <Container
                        onClick={() => handleClick(difficulty.name as string)}
                        opacity={isHighlighted ? 1 : 0.5}
                        borderRadius={3}
                        width="max-content"
                        margin="0 0 10px 0"
                        tabletMargin="auto 15px auto 0"
                        cursor="pointer"
                        backgroundRGBColor={colorsRGB.darkerGreen(0.2)}
                        key={difficulty.id}
                      >
                        <P color="darkerGreen" margin="7px 12px">
                          {difficulty.name}
                        </P>
                      </Container>
                    );
                  })}
                </Container>
              </Container>
            )}

            {allSanityFlavours && (
              <Container
                flexDirection="column"
                width="25%"
                tabletWidth="80%"
                tabletMargin="0px auto"
                mobilePadding="40px 0"
              >
                <H4 margin="0 0 20px 0" fontWeight="normal">
                  Flavours
                </H4>
                {allSanityFlavours.nodes.map((flavour, index) =>
                {
                  if (flavour == null || flavour.name == null)
                  {
                    return null;
                  }
                  const isHighlighted = searchFilters.includes(flavour.name);
                  const isLast = index === allSanityFlavours.nodes.length - 1;
                  return (
                    <Container
                      margin={!isLaptopWidth && isLast ? "0 0 135px 0" : "0 0 12px 0"}
                      onClick={() => handleClick(flavour.name as string)}
                      cursor="pointer"
                      key={flavour.id}
                    >
                      <Icon margin="0 10px 0 0" src={isHighlighted ? icons.checkedDark : icons.uncheckedDark} />
                      <P color="black" margin="auto 20px auto 0">
                        {flavour.name}
                      </P>
                    </Container>
                  );
                })}
              </Container>
            )}
            {!isLaptopWidth && filterOpen && (
              <Container
                backgroundColor="white"
                borderTop={`1px solid ${colorsRGB.black(0.2)}`}
                position="fixed"
                bottom="0px"
                width="100%"
                justifyContent="space-between"
              >
                {searchFilters && searchFilters.length > 0 && (
                  <Container width="100%">
                    <P margin="27px 4%">{searchFilters.length} selected</P>
                    <A
                      margin="auto 0 auto 25px"
                      underlined
                      bold
                      onClick={() =>
                      {
                        useStore.setState({ searchFilters: [] });
                        setHasSearched(false);
                      }}
                    >
                      Clear filter
                    </A>
                  </Container>
                )}
                {searchFilters && searchFilters.length > 0 && (
                  <Container margin="0 4%">
                    <Button
                      margin="12px 0"
                      text="Apply"
                      theme="block"
                      onClick={() =>
                      {
                        setFilterOpen(false);
                        if (isBrowser())
                        {
                          const results = document.querySelector("#searchResults");
                          if (results == null)
                          {
                            return null;
                          }

                          results.scrollIntoView({ behavior: "smooth" });
                          return;
                        }
                        return;
                      }}
                    />
                  </Container>
                )}
              </Container>
            )}
          </FilterContainer>
        </Collapse>
      </Container>
      {!isLaptopWidth && filterOpen && (
        <Container
          zIndex={3}
          width="100vw"
          height="100vh"
          position="absolute"
          top="0px"
          left="0px"
          backgroundRGBColor={colorsRGB.black(0.6)}
        />
      )}
    </>
  );
}

export default FilterBar;
